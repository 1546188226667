<template>
  <section class="wall">
    <b-card class="backgroundWebsiteColor1">
      <hr class="bg-light" />
      <b-card-body class="mx-auto text-white">
        <b-card-title title-tag="h3" class="mt-3 text-center"
          >Menu DEBUG à des fins de test uniquement</b-card-title
        >
        <b-row>
          <b-col cols="0" sm="1" lg="2" xl="4"></b-col>
          <b-col cols="12" sm="10" lg="8" xl="4">
            $route.params.tab -> {{ $route.path }}
            <b-form-group>
              <b-button
                type="submit"
                variant="primary"
                @click="change_setting"
                block
                >Clear localStorage</b-button
              >

              <b-button
                type="submit"
                variant="primary"
                @click="axios_log(isTrueAxiosLog ? false : true)"
                block
              >
                {{ !isTrueAxiosLog ? 'Voir' : 'Supprimer' }}
                les logs Axios
              </b-button>
            </b-form-group>
          </b-col>
          <b-col cols="0" sm="1" lg="2" xl="4"></b-col>
        </b-row>
      </b-card-body>
      <div v-if="isTrueAxiosLog">
        <hr style="border: 1px solid white;" />
        <div>{{ 'dbg_user ' + dbg_user }}</div>
        <div>{{ 'dbg_jwt ' + dbg_jwt }}</div>
        <div>{{ 'dbg_extra_info ' + dbg_extra_info }}</div>
        <pre style="color: white;">{{ axios.debug }}</pre>
      </div>
    </b-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['axios']),
    isTrueAxiosLog() {
      return localStorage.getItem('axios_log') ? true : false
    },
    dbg_user() {
      return localStorage.getItem('user')
    },
    dbg_jwt() {
      return typeof localStorage.getItem('jwt')
    },
    dbg_extra_info() {
      return localStorage.getItem('extra_info')
    },
  },
  methods: {
    change_setting() {
      localStorage.clear()
      document.location.reload(true)
    },
    axios_log(bool) {
      if (bool) localStorage.setItem('axios_log', '1')
      else localStorage.removeItem('axios_log')

      document.location.reload(true)
    },
  },
}
</script>
